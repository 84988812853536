import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import AccessControlGuard from './components/guards/AccessControlGuard'
import AuthGuard from './components/guards/AuthGuard'
import CompassGuard from './components/guards/CompassGuard'
import GuestGuard from './components/guards/GuestGuard'
import AppLayout from './components/layouts/AppLayout'
import ClientDashboardLayout from './components/layouts/DashboardLayout/ClientDashboardLayout'
import CompanyDashboardLayout from './components/layouts/DashboardLayout/CompanyDashboardLayout'
import IcaewDashboardLayout from './components/layouts/DashboardLayout/IcaewDashboardLayout'
import LandingBlackLayout from './components/layouts/LandingBlackLayout/LandingBlackLayout'
import LandingGreyLayout from './components/layouts/LandingGreyLayout'
import LandingWhiteLayout from './components/layouts/LandingWhiteLayout/LandingWhiteLayout'
import MinimalLayout from './components/layouts/MinimalLayout'
import OfficeLayout from './components/layouts/OfficeLayout/OfficeLayout'
import RqCertifiedLayout from './components/layouts/RqCertifiedLayout/RqCertifiedLayout'
import ReferralWithESignatureDetailsProvider from './components/misc/ReferralWithESignatureDetailsProvider'
import SentryErrorBoundary from './components/misc/SentryErrorBoundary'
import LoadingWidget from './components/widgets/LoadingWidget'
import { useCurrentUser } from './hooks/useCurrentUser'
import { useRestoreScrollPosition } from './hooks/useRestoreScrollPosition'
import { isNotProduction } from './lib/helpers/envHelpers'
import { isOfficeAddIn } from './lib/helpers/officeHelpers'
import { ROUTES } from './lib/routes'
import ClientPaymentPage from './pages/ClientPaymentPage'
import CompleteRegistrationPage from './pages/CompleteRegistrationPage'
import IndexPage from './pages/IndexPage'
import { USER_TYPE } from './types/misc'

const AddCompanyPage = lazy(() => import('./pages/AddCompanyPage'))

const AddRelationshipPage = lazy(() => import('./pages/AddRelationshipPage'))

const AddExistingAdviserPage = lazy(
  () => import('./pages/AddExistingAdviserPage'),
)

const AddRelationshipAgreementPage = lazy(
  () => import('./pages/addRelationshipAgreement/AddRelationshipAgreementPage'),
)

const BookMeetingPage = lazy(
  () => import('./pages/bookMeeting/BookMeetingPage'),
)

const CompanyProfilePage = lazy(
  () => import('./pages/companyProfile/CompanyProfilePage'),
)

const ContactsPage = lazy(() => import('./pages/contacts/ContactsPage'))

const CompanyCompassPage = lazy(
  () => import('./pages/companyCompass/CompanyCompassPage'),
)

const CompanyCompassViewPage = lazy(
  () => import('./pages/companyCompassView/CompanyCompassViewPage'),
)

const CompanyCompassDashboardPage = lazy(
  () => import('./pages/companyCompassDashboard/CompanyCompassDashboardPage'),
)

const CompanyCompassGlobalLinkPage = lazy(
  () => import('./pages/companyCompassGlobalLink/CompanyCompassGlobalLinkPage'),
)

const CompanyCompassInvitePage = lazy(
  () => import('./pages/companyCompassInvite/CompanyCompassInvitePage'),
)

const CompanyCompassInvitePreviewPage = lazy(
  () =>
    import(
      './pages/companyCompassInvitePreview/CompanyCompassInvitePreviewPage'
    ),
)

const CompanyCompassSnapshotsPage = lazy(
  () => import('./pages/companyCompassSnapshots/CompanyCompassSnapshotsPage'),
)

const CompassActionsPage = lazy(
  () => import('./pages/compassActions/CompassActionsPage'),
)

const CompassConfirmSharingPage = lazy(
  () => import('./pages/compassConfirmSharing/CompassConfirmSharingPage'),
)

const CompassConfirmStartPage = lazy(
  () => import('./pages/compassConfirmStart/CompassConfirmStartPage'),
)

const CompassConfirmSharingViaGlobalLinkPage = lazy(
  () =>
    import(
      './pages/compassConfirmSharingViaGlobalLink/CompassConfirmSharingViaGlobalLinkPage'
    ),
)

const CompassDashboardPage = lazy(
  () => import('./pages/compassDashboard/CompassDashboardPage'),
)

const CompassDetailsAlreadyOnFilePage = lazy(
  () =>
    import(
      './pages/compassDetailsAlreadyOnFile/CompassDetailsAlreadyOnFilePage'
    ),
)

const CompassHowItWorksPage = lazy(
  () => import('./pages/compassHowItWorks/CompassHowItWorksPage'),
)

const CompassPartnersPage = lazy(
  () => import('./pages/compassPartners/CompassPartnersPage'),
)

const CompassPdfGuidePage = lazy(
  () => import('./pages/compassPdfGuide/CompassPdfGuidePage'),
)

const CompassPdfReportPreviewPage = lazy(
  () => import('./pages/compassPdfReportPreview/CompassPdfReportPreviewPage'),
)

const CompassPdfReportSectionPage = lazy(
  () => import('./pages/compassPdfReportSection/CompassPdfReportSectionPage'),
)

const CompassRedirectPage = lazy(
  () => import('./pages/compassRedirect/CompassRedirectPage'),
)

const CompassSharingPage = lazy(
  () => import('./pages/compassSharing/CompassSharingPage'),
)

const CompassSnapshotPage = lazy(
  () => import('./pages/compassSnapshot/CompassSnapshotPage'),
)

const CompassSnapshotsPage = lazy(
  () => import('./pages/compassSnapshots/CompassSnapshotsPage'),
)

const CompassStartPage = lazy(
  () => import('./pages/compassStart/CompassStartPage'),
)

const CompassUpdatePage = lazy(
  () => import('./pages/compassUpdate/CompassUpdatePage'),
)

const ConfirmConsentPage = lazy(
  () => import('./pages/confirmConsent/ConfirmConsentPage'),
)

const DebugPage = lazy(() => import('./pages/debug/DebugPage'))

const DemoVideosPage = lazy(() => import('./pages/demoVideos/DemoVideosPage'))

const DisclaimerPage = lazy(() => import('./pages/DisclaimerPage'))

const FindProfessionalPage = lazy(() => import('./pages/FindProfessionalPage'))

const GettingStartedPage = lazy(
  () => import('./pages/gettingStarted/GettingStartedPage'),
)

const MobileLandingPage = lazy(() => import('./pages/MobileLandingPage'))

const IcaewOverviewPage = lazy(() => import('./pages/IcaewOverviewPage'))

const IcaewFirmSearchPage = lazy(
  () => import('./pages/icaewFirmSearch/IcaewFirmSearchPage'),
)

const IcaewTrendsPage = lazy(() => import('./pages/IcaewTrendsPage'))

const InvalidOrExpiredPage = lazy(() => import('./pages/InvalidOrExpiredPage'))

const InvitationPage = lazy(() => import('./pages/invitation/InvitationPage'))

const InviteUserPage = lazy(() => import('./pages/inviteUser/InviteUserPage'))

const ManageUsersPage = lazy(
  () => import('./pages/manageUsers/ManageUsersPage'),
)

const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))

const ImportContactsPage = lazy(
  () => import('./pages/importContacts/ImportContactsPage'),
)

const ImportReferralsPage = lazy(
  () => import('./pages/importReferrals/ImportReferralsPage'),
)

const InviteToRqPage = lazy(() => import('./pages/inviteToRq/InviteToRqPage'))

const PreferencesPage = lazy(
  () => import('./pages/preferences/PreferencesPage'),
)

const ProfessionalNetworkApprovalsPage = lazy(
  () =>
    import(
      './pages/professionalNetworkApprovals/ProfessionalNetworkApprovalsPage'
    ),
)

const ProfessionalNetworkRelationshipsPage = lazy(
  () =>
    import(
      './pages/professionalNetworkRelationships/ProfessionalNetworkRelationshipsPage'
    ),
)

const ProfessionalNetworkStatsPage = lazy(
  () => import('./pages/professionalNetworkStats/ProfessionalNetworkStatsPage'),
)

const CompanyAggregatedStatsPage = lazy(
  () => import('./pages/companyAggregatedStats/CompanyAggregatedStatsPage'),
)

const ReferClientPage = lazy(
  () => import('./pages/referClient/ReferClientPage'),
)

const ReferralClientConsentPdfPage = lazy(
  () =>
    import(
      './pages/referralESignatureConsentPdf/ReferralESignatureConsentPdfPage'
    ),
)

const ReferralClientConsentPdfPreviewPage = lazy(
  () =>
    import(
      './pages/referralESignatureConsentPdfPreview/ReferralESignatureConsentPdfPreviewPage'
    ),
)

const ReferralCommissionConsentPage = lazy(
  () =>
    import('./pages/referralCommissionConsent/ReferralCommissionConsentPage'),
)

const ReferralESignatureConsentPage = lazy(
  () =>
    import('./pages/referralESignatureConsent/ReferralESignatureConsentPage'),
)

const ReferralFeedbackPage = lazy(
  () => import('./pages/referralFeedback/ReferralFeedbackPage'),
)

const ReferralLinkPage = lazy(
  () => import('./pages/referralLink/ReferralLinkPage'),
)

const ReferralsPage = lazy(() => import('./pages/referrals/ReferralsPage'))

const ReferralsDashboardPage = lazy(
  () => import('./pages/referralsDashboard/ReferralsDashboardPage'),
)

const ReferralsImportPage = lazy(
  () => import('./pages/referralsImport/ReferralsImportPage'),
)

const ReferralSummaryPage = lazy(
  () => import('./pages/referralSummary/ReferralSummaryPage'),
)

const RegisterPage = lazy(() => import('./pages/RegisterPage'))

const RequestCallbackPage = lazy(
  () => import('./pages/requestCallback/RequestCallbackPage'),
)

const RqCertifiedApplicationStatusPage = lazy(
  () =>
    import(
      './pages/rqCertifiedApplicationStatus/RqCertifiedApplicationStatusPage'
    ),
)

const RqCertifiedApplicationProcessPage = lazy(
  () =>
    import(
      './pages/rqCertifiedApplicationProcess/RqCertifiedApplicationProcessPage'
    ),
)

const RqCertifiedGlossaryPage = lazy(
  () => import('./pages/rqCertifiedGlossary/RqCertifiedGlossaryPage'),
)

const RqCertifiedNdaPdfPage = lazy(
  () => import('./pages/rqCertifiedNdaPdf/RqCertifiedNdaPdfPage'),
)

const RqCertifiedPdfGuidesPage = lazy(
  () => import('./pages/rqCertifiedPdfGuides/RqCertifiedPdfGuidesPage'),
)

const ShortlistPage = lazy(() => import('./pages/ShortlistPage'))

const SignInPage = lazy(() => import('./pages/SignInPage'))

const SignOutPage = lazy(() => import('./pages/SignOutPage'))

const SuspendedPage = lazy(() => import('./pages/SuspendedPage'))

const UserCompassPage = lazy(
  () => import('./pages/userCompass/UserCompassPage'),
)

const WalkthroughPage = lazy(
  () => import('./pages/walkthrough/WalkthroughPage'),
)

const WelcomePage = lazy(() => import('./pages/WelcomePage'))

const WithdrawGdprConsentPage = lazy(
  () => import('./pages/withdrawGdprConsent/WithdrawGdprConsentPage'),
)

const Office365TaskPanePage = lazy(
  () => import('./pages/office365TaskPane/Office365TaskPanePage'),
)

interface AppRoute {
  path: string
  element: React.ReactNode
}

const accountSetupRoutes: AppRoute[] = [
  {
    path: ROUTES.register,
    element: <RegisterPage />,
  },
  {
    path: ROUTES.welcome,
    element: <WelcomePage />,
  },
]

const guestRoutes: AppRoute[] = [
  {
    path: ROUTES.index,
    element: <IndexPage />,
  },
  {
    path: ROUTES.signIn,
    element: (
      <GuestGuard>
        <SignInPage />
      </GuestGuard>
    ),
  },
  {
    path: ROUTES.signOut,
    element: <SignOutPage />,
  },
]

const companyDashboardRoutes: AppRoute[] = [
  { path: ROUTES.addCompany, element: <AddCompanyPage /> },
  { path: ROUTES.addExistingAdviser, element: <AddExistingAdviserPage /> },
  {
    path: ROUTES.addRelationshipAgreement,
    element: <AddRelationshipAgreementPage />,
  },
  { path: ROUTES.addRelationship, element: <AddRelationshipPage /> },
  { path: ROUTES.bookMeeting, element: <BookMeetingPage /> },
  { path: ROUTES.companyProfile, element: <CompanyProfilePage /> },
  {
    path: ROUTES.companyCompassDashboard,
    element: <CompanyCompassDashboardPage />,
  },
  {
    path: ROUTES.companyCompassGlobalLink,
    element: <CompanyCompassGlobalLinkPage />,
  },
  {
    path: ROUTES.companyCompassSnapshots,
    element: <CompanyCompassSnapshotsPage />,
  },
  {
    path: ROUTES.compassConfirmStart,
    element: <CompassConfirmStartPage />,
  },
  { path: ROUTES.contacts, element: <ContactsPage /> },
  {
    path: ROUTES.debug,
    element: (
      <AccessControlGuard
        guard={isNotProduction()}
        onDenied={{ redirect: { to: ROUTES.index } }}
      >
        <DebugPage />
      </AccessControlGuard>
    ),
  },
  { path: ROUTES.demoVideos, element: <DemoVideosPage /> },
  { path: ROUTES.disclaimer, element: <DisclaimerPage /> },
  { path: ROUTES.importContacts, element: <ImportContactsPage /> },
  { path: ROUTES.inviteToRq, element: <InviteToRqPage /> },

  { path: ROUTES.findProfessional, element: <FindProfessionalPage /> },
  { path: ROUTES.gettingStarted, element: <GettingStartedPage /> },
  { path: ROUTES.mobileLanding, element: <MobileLandingPage /> },
  { path: ROUTES.manageUsers, element: <ManageUsersPage /> },
  { path: ROUTES.preferences, element: <PreferencesPage /> },
  {
    path: ROUTES.professionalNetworkApprovals,
    element: <ProfessionalNetworkApprovalsPage />,
  },
  { path: ROUTES.referClient, element: <ReferClientPage /> },
  { path: ROUTES.referrals, element: <ReferralsPage /> },
  { path: ROUTES.referralsDashboard, element: <ReferralsDashboardPage /> },
  {
    path: ROUTES.referralsImport,
    element: <ReferralsImportPage />,
  },
  {
    path: ROUTES.referralsImportIndividualUpload,
    element: <ImportReferralsPage />,
  },
  { path: ROUTES.referralSummary, element: <ReferralSummaryPage /> },
  {
    path: ROUTES.professionalNetworkRelationships,
    element: <ProfessionalNetworkRelationshipsPage />,
  },
  {
    path: ROUTES.professionalNetworkStats,
    element: <ProfessionalNetworkStatsPage />,
  },
  {
    path: ROUTES.companyAggregatedStats,
    element: <CompanyAggregatedStatsPage />,
  },
  {
    path: ROUTES.rqCertifiedApplicationProcess,
    element: (
      <RqCertifiedLayout>
        <RqCertifiedApplicationProcessPage />
      </RqCertifiedLayout>
    ),
  },
  {
    path: ROUTES.rqCertifiedApplicationStatus,
    element: (
      <RqCertifiedLayout>
        <RqCertifiedApplicationStatusPage />
      </RqCertifiedLayout>
    ),
  },
  {
    path: ROUTES.rqCertifiedGlossary,
    element: (
      <RqCertifiedLayout>
        <RqCertifiedGlossaryPage />
      </RqCertifiedLayout>
    ),
  },
  {
    path: ROUTES.rqCertifiedPdfGuides,
    element: (
      <RqCertifiedLayout>
        <RqCertifiedPdfGuidesPage />
      </RqCertifiedLayout>
    ),
  },
]

const clientDashboardRoutes: AppRoute[] = [
  { path: ROUTES.compassAddCompany, element: <AddCompanyPage /> },
  { path: ROUTES.compassActions, element: <CompassActionsPage /> },
  {
    path: ROUTES.compassConfirmSharing,
    element: <CompassConfirmSharingPage />,
  },
  {
    path: ROUTES.compassConfirmSharingViaGlobalLink,
    element: <CompassConfirmSharingViaGlobalLinkPage />,
  },
  { path: ROUTES.compassDashboard, element: <CompassDashboardPage /> },
  {
    path: ROUTES.compassDetailsAlreadyOnFile,
    element: <CompassDetailsAlreadyOnFilePage />,
  },
  { path: ROUTES.compassDisclaimer, element: <DisclaimerPage /> },
  { path: ROUTES.compassHowItWorks, element: <CompassHowItWorksPage /> },
  { path: ROUTES.compassPartners, element: <CompassPartnersPage /> },
  { path: ROUTES.compassSnapshots, element: <CompassSnapshotsPage /> },
  { path: ROUTES.compassSnapshot, element: <CompassSnapshotPage /> },
  { path: ROUTES.compassSharing, element: <CompassSharingPage /> },
  { path: ROUTES.compassStart, element: <CompassStartPage /> },
  { path: ROUTES.compassUpdate, element: <CompassUpdatePage /> },
]

const guestLandingRoutes: AppRoute[] = [
  { path: ROUTES.confirmConsent, element: <ConfirmConsentPage /> },
  { path: ROUTES.inviteUser, element: <InviteUserPage /> },
  { path: ROUTES.requestCallback, element: <RequestCallbackPage /> },
  { path: ROUTES.withdrawGdprConsent, element: <WithdrawGdprConsentPage /> },
  { path: ROUTES.clientPayment, element: <ClientPaymentPage /> },
]

const guestLandingWhiteTemplateRoutes: AppRoute[] = [
  { path: ROUTES.invitation, element: <InvitationPage /> },
  {
    path: ROUTES.commissionConsent,
    element: <ReferralCommissionConsentPage />,
  },
  { path: ROUTES.referralFeedback, element: <ReferralFeedbackPage /> },
]

const companyUserLandingRoutes: AppRoute[] = [
  { path: ROUTES.suspended, element: <SuspendedPage /> },
  { path: ROUTES.shortlist, element: <ShortlistPage /> },
]

const icaewRoutes: AppRoute[] = [
  { path: ROUTES.icaewDisclaimer, element: <DisclaimerPage /> },
  { path: ROUTES.icaewOverview, element: <IcaewOverviewPage /> },
  { path: ROUTES.icaewFirmSearch, element: <IcaewFirmSearchPage /> },
  { path: ROUTES.icaewTrends, element: <IcaewTrendsPage /> },
]

const miscRoutes: AppRoute[] = [
  { path: ROUTES.compassPdfGuide, element: <CompassPdfGuidePage /> },
  {
    path: ROUTES.companyCompass,
    element: (
      <AuthGuard requiredUserType={USER_TYPE.company}>
        <CompanyCompassPage />
      </AuthGuard>
    ),
  },
  { path: ROUTES.companyCompassInvite, element: <CompanyCompassInvitePage /> },
  {
    path: ROUTES.companyCompassInvitePreview,
    element: <CompanyCompassInvitePreviewPage />,
  },
  {
    path: ROUTES.companyCompassView,
    element: (
      <AuthGuard requiredUserType={USER_TYPE.company}>
        <CompanyCompassViewPage />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.compassPdfReportPreview,
    element: (
      <AuthGuard requiredUserType={USER_TYPE.company}>
        <CompassPdfReportPreviewPage />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.compassPdfReportSection,
    element: <CompassPdfReportSectionPage />,
  },
  { path: ROUTES.compassRedirect, element: <CompassRedirectPage /> },
  {
    path: ROUTES.completeRegistration,
    element: (
      <AuthGuard requiredUserType={USER_TYPE.company}>
        <CompleteRegistrationPage />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.officeTaskPane,
    element: (
      <OfficeLayout>
        <Office365TaskPanePage />
      </OfficeLayout>
    ),
  },
  {
    path: ROUTES.referralESignatureConsent,
    element: (
      <ReferralWithESignatureDetailsProvider>
        <ReferralESignatureConsentPage />
      </ReferralWithESignatureDetailsProvider>
    ),
  },
  {
    path: ROUTES.referralESignatureConsentPdf,
    element: (
      <ReferralWithESignatureDetailsProvider>
        <ReferralClientConsentPdfPage />
      </ReferralWithESignatureDetailsProvider>
    ),
  },
  {
    path: ROUTES.referralESignatureConsentPdfPreview,
    element: (
      <AuthGuard requiredUserType={USER_TYPE.company}>
        <ReferralClientConsentPdfPreviewPage />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.referralLink,
    element: (
      <SentryErrorBoundary
        variant="full-screen"
        fallbackComponent={<InvalidOrExpiredPage />}
      >
        <Suspense fallback={<LoadingWidget />}>
          <ReferralLinkPage />
        </Suspense>
      </SentryErrorBoundary>
    ),
  },
  {
    path: ROUTES.rqCertifiedNdaPdf,
    element: <RqCertifiedNdaPdfPage />,
  },
  {
    path: ROUTES.userCompass,
    element: (
      <CompassGuard>
        <UserCompassPage />
      </CompassGuard>
    ),
  },
  {
    path: ROUTES.walkthrough,
    element: (
      <AuthGuard requiredUserType={USER_TYPE.company}>
        <WalkthroughPage />
      </AuthGuard>
    ),
  },
]

const SentryAwareRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const Root: React.FC = () => {
  const location = useLocation()
  const currentUser = useCurrentUser()

  useEffect(() => {
    // Temporary redirect - should be done in Cloudflare however we need to support the old version of Outlook add-in for now
    const currentHost = window.location.hostname

    if (!isOfficeAddIn() && currentHost === 'app.rqratings.com') {
      window.location.href = window.location.href.replace(
        'app.rqratings.com',
        'refer.rq.app',
      )
    }
  }, [])

  React.useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  useRestoreScrollPosition()

  return (
    <SentryAwareRoutes>
      <Route element={<AppLayout />}>
        {/* Guest */}
        {guestRoutes.map((route) => (
          <Route path={route.path} element={route.element} key={route.path} />
        ))}

        {/* Account setup */}
        {accountSetupRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <AuthGuard>
                <LandingGreyLayout variant="card">
                  {route.element}
                </LandingGreyLayout>
              </AuthGuard>
            }
            key={route.path}
          />
        ))}

        {/* Company dashboard */}
        {companyDashboardRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <AuthGuard requiredUserType={USER_TYPE.company}>
                <AccessControlGuard
                  guard={!!currentUser?.hasCompletedWalkthrough}
                  onDenied={{ redirect: { to: ROUTES.walkthrough } }}
                >
                  <CompanyDashboardLayout>
                    {route.element}
                  </CompanyDashboardLayout>
                </AccessControlGuard>
              </AuthGuard>
            }
            key={route.path}
          />
        ))}

        {/* Client dashboard */}
        {clientDashboardRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <AuthGuard requiredUserType={USER_TYPE.client}>
                <ClientDashboardLayout>{route.element}</ClientDashboardLayout>
              </AuthGuard>
            }
            key={route.path}
          />
        ))}

        {/* Company user landing routes */}
        {companyUserLandingRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <AuthGuard requiredUserType={USER_TYPE.company}>
                <LandingBlackLayout>{route.element}</LandingBlackLayout>
              </AuthGuard>
            }
            key={route.path}
          />
        ))}

        {/* ICAEW */}
        {icaewRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <AuthGuard requiredUserType={USER_TYPE.icaew}>
                <IcaewDashboardLayout>{route.element}</IcaewDashboardLayout>
              </AuthGuard>
            }
            key={route.path}
          />
        ))}

        {/* Guest landing */}
        {guestLandingRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <LandingBlackLayout>
                <MinimalLayout>{route.element}</MinimalLayout>
              </LandingBlackLayout>
            }
            key={route.path}
          />
        ))}

        {/* Guest landing - white template */}
        {guestLandingWhiteTemplateRoutes.map((route) => (
          <Route
            path={route.path}
            element={<LandingWhiteLayout>{route.element}</LandingWhiteLayout>}
            key={route.path}
          />
        ))}

        {/* Misc */}
        {miscRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <SentryErrorBoundary
                key={location.pathname}
                variant="full-screen"
              >
                <Suspense fallback={<LoadingWidget />}>
                  {route.element}
                </Suspense>
              </SentryErrorBoundary>
            }
            key={route.path}
          />
        ))}

        <Route
          path={ROUTES.invalidOrExpired}
          element={<InvalidOrExpiredPage />}
        />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </SentryAwareRoutes>
  )
}

export default Root
