import { useQuery } from '@tanstack/react-query'

import { isDevelopment } from '../lib/helpers/envHelpers'
import { QUERY_KEYS } from '../lib/queryKeys'
import referralService from '../lib/services/referralService'
import { useParamOrFail } from './useParamOrFail'

export function useReferralWithESignatureDetailsQuery() {
  const referralId = +useParamOrFail('referralId')
  const callbackCode = useParamOrFail('callbackCode')

  return useQuery({
    queryKey: QUERY_KEYS.referralWithESignatureDetails(referralId),
    queryFn: () =>
      referralService.getReferralWithESignatureDetails(
        referralId,
        callbackCode,
      ),
    refetchOnWindowFocus: false,
    retry: isDevelopment() ? false : undefined,
  })
}
