import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@rq-ratings/pro-light-svg-icons'
import { onlineManager } from '@tanstack/react-query'
import React, { useEffect } from 'react'

import useAppSelector from '../../hooks/useAppSelector'
import { useCaptureErrorShownToUserEvent } from '../../hooks/useCaptureErrorShownToUserEvent'
import { COMMON_DATA_ERROR_MESSAGE, EMAIL_ADDRESSES } from '../../lib/constants'
import { selectSessionMetadata } from '../../redux/slices/session'
import CommonDataErrorMessage from '../utils/CommonDataErrorMessage'
import DevOnly from '../utils/DevOnly'
import PlainButton from './PlainButton'

const ErrorMessage: React.FC<{ error?: unknown }> = ({ error }) => {
  const isOnline = onlineManager.isOnline()
  const sessionMetadata = useAppSelector(selectSessionMetadata)
  const captureErrorShownToUserEvent = useCaptureErrorShownToUserEvent()

  useEffect(() => {
    // Sometimes this component may render for a split second before the user
    // is redirected to another route (e.g., when a session expires). In such
    // cases, we don't want to capture the error so let's wait for 2 seconds
    // before capturing the error.
    const captureErrorEventTimer = setTimeout(
      captureErrorShownToUserEvent,
      2000,
    )

    // Clear the timer if the component unmounts
    return () => {
      clearTimeout(captureErrorEventTimer)
    }
  }, [captureErrorShownToUserEvent, sessionMetadata])

  return (
    <section className="position-relative">
      <PlainButton
        className="position-absolute top-0 end-0 text-reset hover-rotate"
        title="Reload page"
        onClick={() => window.location.reload()}
      >
        <FontAwesomeIcon icon={faSync} style={{ fontSize: '22px' }} />
      </PlainButton>

      {isOnline ? (
        <DefaultErrorMessage error={error} />
      ) : (
        <OfflineErrorMessage />
      )}
    </section>
  )
}

const DefaultErrorMessage: React.FC<{ error?: unknown }> = ({ error }) => {
  const isCommonDataError =
    error instanceof Error && error?.message.includes(COMMON_DATA_ERROR_MESSAGE)

  return (
    <div>
      <h2>Oops.</h2>

      <p>
        Something seems to have gone wrong on our side. Our team has been
        notified.
      </p>

      <p>
        Try to refresh this page. If the problem persists, you can contact us at{' '}
        <a href={`mailto:${EMAIL_ADDRESSES.rqHello}?subject=Website error`}>
          {EMAIL_ADDRESSES.rqHello}
        </a>{' '}
        and we can help out!
      </p>

      <DevOnly>
        <div className="d-flex flex-column">
          {isCommonDataError && <CommonDataErrorMessage />}

          <ul>
            <li>
              <a
                href={`${import.meta.env.VITE_RQ_API_URL}/_profiler/latest`}
                target="_blank"
                rel="noreferrer"
              >
                View last API request
              </a>
            </li>

            <li>
              <a
                href={`${import.meta.env.VITE_RQ_API_URL}/_profiler/search?limit=10`}
                target="_blank"
                rel="noreferrer"
              >
                View last 10 API requests
              </a>
            </li>
          </ul>
        </div>
      </DevOnly>
    </div>
  )
}

const OfflineErrorMessage: React.FC = () => (
  <>
    <h2>Oops.</h2>

    <p>
      It seems there's a problem with your network. Please check your internet
      connection and then try refreshing the page.
    </p>
  </>
)

export default ErrorMessage
