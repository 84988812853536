import React from 'react'
import { Button } from 'react-bootstrap'

import { useAuth } from '../../hooks/useAuth'
import { useCurrentCompany } from '../../hooks/useCurrentCompany'
import useModal from '../../hooks/useModal'
import {
  CLIENT_CHANGED,
  COMPANY_CHANGED,
  useUserContextChanged,
} from '../../hooks/useUserContextChanged'
import OverlayCard from './OverlayCard'

const UserChangedContextWarning: React.FC = () => {
  const { isClientUser } = useAuth()
  const currentCompany = useCurrentCompany()
  const { isShowingModal, showModal, hideModal } = useModal()

  useUserContextChanged((message) => {
    switch (message.type) {
      case COMPANY_CHANGED:
        if (currentCompany?.id === message.payload.company?.id) {
          hideModal()
        } else {
          showModal()
        }
        break

      case CLIENT_CHANGED:
        if (isClientUser) {
          hideModal()
        } else {
          showModal()
        }
        break

      default:
        showModal()
        console.log('Unknown context change')
    }
  })

  if (isShowingModal) {
    return (
      <OverlayCard className="mt-3 mt-sm-6" variant="dark" fullScreen>
        <h2>Session Conflict Detected</h2>
        <p>
          It appears that you are logged in as a different company or user in
          another browser window. Please refresh the page to proceed.
        </p>

        <Button
          variant="primary"
          size="lg"
          onClick={async () => {
            window.location.reload()
          }}
        >
          Refresh
        </Button>
      </OverlayCard>
    )
  }

  return null
}

export default UserChangedContextWarning
