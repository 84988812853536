import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@rq-ratings/pro-solid-svg-icons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'

import useCurrentCompanyOrFail from '../../../../../../hooks/useCurrentCompanyOrFail'
import { QUERY_KEYS } from '../../../../../../lib/queryKeys'
import companyService from '../../../../../../lib/services/companyService'
import { CompanyServiceItem } from '../../../../../../types/responses/companies'
import CompanyFcaServiceDetailsRow, {
  ServiceFormValues,
} from './CompanyFcaServiceDetailsRow'

const CompanyFcaServices: React.FC = () => {
  const currentCompany = useCurrentCompanyOrFail()
  const [newService, setNewService] = useState<ServiceFormValues | null>(null)
  const queryClient = useQueryClient()

  const servicesQuery = useQuery({
    queryKey: QUERY_KEYS.companyServices(currentCompany.id),
    queryFn: () => companyService.getCompanyServices(currentCompany.id),
  })

  if (servicesQuery.isLoading || !servicesQuery.data) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <Spinner />
      </div>
    )
  }

  const services = servicesQuery.data.map(
    (service: CompanyServiceItem): ServiceFormValues => ({
      id: service.id,
      company: currentCompany['@id'],
      serviceArea: service.serviceArea,
      isOnlyInConjunctionWithFP: !service.isStandaloneService,
      notes: service.notes ?? '',
      serviceType: service.serviceType?.['@id'],
    }),
  )

  const hasServices = services.length > 0

  return (
    <section className="pop-in">
      <div className="d-flex flex-column gap-3 align-items-stretch">
        {services.map((service, index) => (
          <CompanyFcaServiceDetailsRow
            key={`${index}-${service.id}`}
            isEditingByDefault={false}
            rowNumber={index}
            service={service}
            onDelete={() => {
              queryClient.invalidateQueries({
                queryKey: QUERY_KEYS.companyServices(currentCompany.id),
              })
            }}
          />
        ))}

        {newService && (
          <CompanyFcaServiceDetailsRow
            key="new"
            isEditingByDefault={true}
            rowNumber={-1}
            service={newService}
            // We're done with the new one now - it's in the collection
            onSave={async () => {
              await queryClient.invalidateQueries({
                queryKey: QUERY_KEYS.companyServices(currentCompany.id),
              })

              setNewService(null)
            }}
            onDelete={() => setNewService(null)}
          />
        )}
      </div>

      {!newService && (
        <div
          className={classNames(
            'd-flex justify-content-center',
            hasServices ? 'mt-3' : 'mb-5',
          )}
        >
          <Button
            onClick={() =>
              setNewService({
                id: undefined,
                company: currentCompany['@id'],
                serviceArea: undefined,
                isOnlyInConjunctionWithFP: false,
                notes: '',
                serviceType: undefined,
              })
            }
            variant={hasServices ? 'link' : 'success'}
            size="lg"
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            {hasServices ? 'Add another service' : 'Add a service'}
          </Button>
        </div>
      )}
    </section>
  )
}

export default CompanyFcaServices
