import './CompleteRegistrationFlow.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@rq-ratings/pro-regular-svg-icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { CSSProperties, useState } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useMount } from 'react-use'
import invariant from 'tiny-invariant'
import { objectKeys } from 'ts-extras'

import usePosthogCapture from '../../../hooks/posthogCapture/usePosthogCapture'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import { useCompanyProfileQuery } from '../../../hooks/useCompanyProfileQuery'
import useCurrentCompanyOrFail from '../../../hooks/useCurrentCompanyOrFail'
import { useInvalidateOnboardingChecklistQuery } from '../../../hooks/useInvalidateOnboardingChecklistQuery'
import useNotyf from '../../../hooks/useNotyf'
import { COMPANY_IDENTIFIER, POSTHOG_APP_AREA } from '../../../lib/constants'
import { QUERY_KEYS } from '../../../lib/queryKeys'
import addressService from '../../../lib/services/addressService'
import companyExpertiseAreaService from '../../../lib/services/companyExpertiseAreaService'
import companyService from '../../../lib/services/companyService'
import { selectExpertiseAreasByCompanyTypeIri } from '../../../redux/slices/commonData'
import {
  selectIsCompanyServicesValid,
  selectIsEditing,
} from '../../../redux/slices/completeRegistrationPopup'
import { completeCompanyRegistration } from '../../../redux/slices/session'
import ActionButton from '../../misc/ActionButton'
import CompanyServices from './steps/1_CompanyServices/CompanyServices'
import CompanyExpertiseAreas from './steps/2_CompanyExpertiseAreas/CompanyExpertiseAreas'
import CompanyOfficeLocations from './steps/3_CompanyOfficeLocations/CompanyOfficeLocations'
import MeetingLink from './steps/4_MeetingLink/MeetingLink'
import UploadLogo from './steps/5_UploadLogo/UploadLogo'
import UploadVideo from './steps/6_UploadVideo/UploadVideo'

interface Props {
  /** If you don't pass this, the modal can't be hidden */
  onHide?: () => void
  /** Optionally do something when this flow is completed */
  onComplete?: () => void
}

export type CompleteRegistrationFlowStepKey =
  | 'companyServices'
  | 'companyExpertiseAreas'
  | 'companyOfficeLocations'
  | 'meetingLink'
  | 'uploadLogo'
  | 'uploadVideo'

interface CompleteRegistrationFlowStepDetails {
  title: string
  name: string
  render: () => React.ReactNode
  isComplete: () => boolean
  isRequired: boolean
  isNecessary: boolean
}

const CompleteRegistrationFlow: React.FC<Props> = ({ onHide, onComplete }) => {
  const currentCompany = useCurrentCompanyOrFail()
  const dispatch = useAppDispatch()
  const notyf = useNotyf()
  const isCompanyServicesValid = useAppSelector(selectIsCompanyServicesValid)
  const isEditing = useAppSelector(selectIsEditing)
  const { posthogCapture } = usePosthogCapture()
  const expertiseAreas = useAppSelector(
    selectExpertiseAreasByCompanyTypeIri(currentCompany.companyType['@id']),
  )

  const companyExpertiseAreasQuery = useQuery({
    queryKey: ['company-expertise-areas', currentCompany.id],
    queryFn: () => companyExpertiseAreaService.getCollectionForCurrentCompany(),
  })

  const addressesQuery = useQuery({
    queryKey: QUERY_KEYS.addresses(),
    queryFn: () => addressService.getCurrentCompanyAddresses(),
  })

  const companyProfileQuery = useCompanyProfileQuery({
    identifier: currentCompany.id.toString(),
    identifierType: COMPANY_IDENTIFIER.companyId,
  })

  const { companiesHouse, fca } = companyProfileQuery.data
  const isTradingName = !!fca?.frnTradingNameId
  const hasDefaultHq =
    !isTradingName && companiesHouse && !!companiesHouse.address

  useMount(() => {
    posthogCapture({
      appArea: POSTHOG_APP_AREA.completeRegistrationModal,
      action: 'started',
    })
  })

  const flowSteps: Record<
    CompleteRegistrationFlowStepKey,
    CompleteRegistrationFlowStepDetails
  > = {
    companyServices: {
      title: 'What services does your firm offer?',
      name: 'List your services',
      render: () => <CompanyServices />,
      // Probably always true, since companies get default services and can't remove them
      isComplete: () => isCompanyServicesValid,
      isRequired: true,
      isNecessary: true,
    },

    companyExpertiseAreas: {
      title: "What are your firm's areas of expertise?",
      name: 'Select your areas of expertise',
      render: () => <CompanyExpertiseAreas />,
      isComplete: () =>
        !!companyExpertiseAreasQuery.data &&
        companyExpertiseAreasQuery.data.length > 0,
      isRequired: false,
      isNecessary: expertiseAreas.length > 0,
    },

    companyOfficeLocations: {
      title: "Where are your firm's office locations?",
      name: 'Enter your office locations',
      render: () => <CompanyOfficeLocations />,
      isComplete: () =>
        hasDefaultHq ||
        (addressesQuery.data !== undefined && addressesQuery.data.length > 0),
      // This is only for additional addresses, hence not required
      isRequired: true,
      isNecessary: true,
    },

    meetingLink: {
      title: 'Your meeting links',
      name: 'Provide your meeting links',
      render: () => <MeetingLink />,
      isComplete: () =>
        !!currentCompany.scheduleClientCallUrl &&
        !!currentCompany.scheduleCompanyCallUrl,
      isRequired: false,
      isNecessary: true,
    },

    uploadLogo: {
      title: "Upload your firm's logo",
      name: "Upload your firm's logo",
      render: () => <UploadLogo />,
      isComplete: () => !!currentCompany.logoUrl,
      isRequired: true,
      isNecessary: true,
    },

    uploadVideo: {
      title: 'Share a video about you or your firm (optional)',
      name: 'Upload video about your firm',
      render: () => <UploadVideo />,
      isComplete: () =>
        !!currentCompany.videoUrl || currentCompany.hasUnprocessedVideo,
      isRequired: false,
      isNecessary: true,
    },
  }

  const [currentStepKey, setCurrentStepKey] =
    useState<CompleteRegistrationFlowStepKey>(
      objectKeys(flowSteps)[0] as CompleteRegistrationFlowStepKey,
    )
  const [visitedSteps, setVisitedSteps] = useState<string[]>([currentStepKey])

  function currentStepIsFirstStep(): boolean {
    const flowStepKeys = Object.keys(flowSteps)

    return currentStepKey === flowStepKeys[0]
  }

  function currentStepIsLastStep(): boolean {
    const flowStepKeys = Object.keys(flowSteps)

    return currentStepKey === flowStepKeys[flowStepKeys.length - 1]
  }

  function goToStepAfter(step: CompleteRegistrationFlowStepKey): void {
    const flowStepKeys = objectKeys(flowSteps)
    const currentStepIndex = flowStepKeys.indexOf(step)

    const newStepKey =
      flowStepKeys[
        // Don't go past the last one
        Math.min(currentStepIndex + 1, flowStepKeys.length - 1)
      ]

    invariant(newStepKey, 'Expected newStepKey to be defined')

    const newStep = flowSteps[newStepKey]
    invariant(newStep, 'Expected newStep to be defined')

    if (!newStep.isNecessary) {
      // Skip this unnecessary step, and move forward again
      goToStepAfter(newStepKey)

      return
    }

    goToStep(newStepKey)
  }

  function goToStepBefore(current: CompleteRegistrationFlowStepKey): void {
    const flowStepKeys = Object.keys(
      flowSteps,
    ) as CompleteRegistrationFlowStepKey[]
    const currentStepIndex = flowStepKeys.indexOf(current)

    const newStepKey = flowStepKeys[
      // Don't go past the first one
      Math.max(currentStepIndex - 1, 0)
    ] as CompleteRegistrationFlowStepKey

    if (!flowSteps[newStepKey]?.isNecessary) {
      // Skip this unnecessary step, and move back again
      goToStepBefore(newStepKey)

      return
    }

    goToStep(newStepKey)
  }

  function goToStep(key: CompleteRegistrationFlowStepKey) {
    if (
      isEditing &&
      !confirm(
        'Changes will be lost if you move away from this step. Move away?',
      )
    ) {
      // Cancel the navigation
      return
    }

    if (!visitedSteps.includes(key)) {
      setVisitedSteps([...visitedSteps, key])
    }

    setCurrentStepKey(key)
  }

  function isReadyToComplete(): boolean {
    for (const step of Object.values(flowSteps)) {
      if (step.isRequired && !step.isComplete()) {
        return false
      }
    }

    return true
  }

  const invalidateOnboardingChecklistQuery =
    useInvalidateOnboardingChecklistQuery()

  const completeRegistrationMutation = useMutation({
    mutationFn: () => companyService.completeRegistration(currentCompany.id),
    onSuccess: async () => {
      dispatch(completeCompanyRegistration())

      await invalidateOnboardingChecklistQuery()
      notyf.success('Registration successfully completed')

      posthogCapture({
        appArea: POSTHOG_APP_AREA.completeRegistrationModal,
        action: 'step-completed',
        step: 'uploadVideo',
      })

      posthogCapture({
        appArea: POSTHOG_APP_AREA.completeRegistrationModal,
        action: 'completed',
      })

      if (onComplete) {
        onComplete()
      }
    },
    onError: () => {
      notyf.error('There was a problem completing your registration')
    },
  })

  if (currentCompany.isRegistrationComplete) {
    return null
  }

  const currentStep = flowSteps[currentStepKey]

  invariant(currentStep, 'Expected currentStep to be defined')

  const isNavigationAllowed =
    !currentStep.isRequired || currentStep.isComplete()

  function hideModal() {
    if (!onHide) {
      return
    }

    if (
      isEditing &&
      !window.confirm('Changes will be lost if you close this popup')
    ) {
      return
    }

    onHide()
  }

  function handleCompleteStep() {
    posthogCapture({
      appArea: POSTHOG_APP_AREA.completeRegistrationModal,
      action: 'step-completed',
      step: currentStepKey,
    })

    goToStepAfter(currentStepKey)
  }

  return (
    <Modal
      id="complete-registration-modal"
      show
      centered
      size="lg"
      fullscreen="md-down"
      onHide={hideModal}
    >
      {onHide && (
        <FontAwesomeIcon
          onClick={onHide}
          icon={faTimes}
          className="position-fixed text-black fs-1 d-md-none"
          style={{
            right: 0,
            top: 0,
            padding: '16px',
          }}
        />
      )}

      <Modal.Header className="border-0 d-flex justify-content-center">
        <Modal.Title>
          <h2 className="h1 mt-5 mt-md-3 text-center fw-bolder">
            {currentStep.title}
          </h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column justify-content-between text-center">
        <div className="mb-3 flex-grow-1">{currentStep.render()}</div>
      </Modal.Body>

      <Modal.Footer className="sticky-bottom d-flex flex-column">
        {/* Step indicators */}
        <div
          id="complete-registration-modal-steps-indicator"
          style={
            {
              '--number-of-columns': Object.values(flowSteps).filter(
                (step) => step.isNecessary,
              ).length,
            } as CSSProperties
          }
          className="mb-3"
        >
          {objectKeys(flowSteps).map(
            (stepKey: CompleteRegistrationFlowStepKey) => {
              if (!flowSteps[stepKey]?.isNecessary) {
                return null
              }

              return (
                <OverlayTrigger
                  key={stepKey}
                  overlay={<Tooltip>{flowSteps[stepKey].name}</Tooltip>}
                >
                  {/* The clickable area, mostly invisible. Bigger than the
                      visible bit as otherwise the click/touch target is too small */}
                  <button
                    type="button"
                    onClick={() => {
                      if (isNavigationAllowed) {
                        goToStep(stepKey)
                      } else {
                        notyf.errorWithoutReporting(
                          'Please complete this step before moving to another',
                        )
                      }
                    }}
                  >
                    {/* The visual bit */}
                    <span
                      className={stepKey === currentStepKey ? 'bg-primary' : ''}
                    ></span>
                  </button>
                </OverlayTrigger>
              )
            },
          )}
        </div>

        {/* Step actions */}
        <div className="d-flex gap-3">
          {!currentStepIsFirstStep() && (
            <Button
              variant="outline-secondary"
              size="lg"
              onClick={() => goToStepBefore(currentStepKey)}
              disabled={!isNavigationAllowed}
            >
              Back
            </Button>
          )}

          {!currentStepIsLastStep() && (
            <Button
              variant="primary"
              size="lg"
              onClick={handleCompleteStep}
              disabled={!isNavigationAllowed}
            >
              Next
            </Button>
          )}

          {currentStepIsLastStep() && (
            <ActionButton
              variant="success"
              size="lg"
              onClick={() => completeRegistrationMutation.mutate()}
              disabled={!isReadyToComplete()}
              isProcessing={completeRegistrationMutation.isPending}
            >
              Finish
            </ActionButton>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default CompleteRegistrationFlow
